.about-us-page{
    display:flex;
    width:100%;
    flex-wrap: wrap;
}
.about-us-uprow{
    width:100%;
    display:flex;
}
.about-us-title{
    width:50%;
    font-size: 10rem;
    font-weight: 900;
    display:flex;
    align-items: center;
    box-sizing: border-box;
    padding:2%;
    color:white;
}
.about-us-uprow-column{
    width:50%;
}
.about-us-uprow-column-textbox{
    margin-top:80px;
    padding:24px;
    color:white
}
.about-us-uprow-image{
    width:100%;
    object-position: top;
    border-bottom-left-radius: 18px;
}
.about-us-subtitles{
    font-weight: 900;
    font-size: 40px;
    margin-bottom:8px;
}
.about-us-row{
    width:100%;
    height:80vh;
    display:flex;
    padding:32px;
}
.about-us-row-image{
    width:70%;
    height:100%;
    border-radius:18px;
    box-sizing: border-box;
    object-fit: cover;
    object-position: top;
}
.about-us-row-textbox{
    width:30%;
    padding:32px;
    box-sizing: border-box;
    display:flex;
    flex-wrap: wrap;
    align-items: end;
    align-content: end;
    color:white;
}
.about-us-last-textbox{
    width:100%;
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    padding:0 6%;
    margin-top: 10%;
    color:white;
}
.about-us-last-image{
    width:100%;
    height:100vh;
    object-position:50% 25%;
    object-fit: cover;
    margin-top:5%;
    margin-bottom:10%;
}


@media only screen and (max-width:768px){
    .about-us-row{
        flex-wrap: wrap;
        width:100%;
        height:min-content
    }
    .about-us-row-image{
        width:100%;
        object-fit: cover;
    }
    .about-us-row-textbox{
        width:100%;
        justify-content: center;
    }
    .about-us-title{
        display:none;
    }
    .about-us-uprow-image{
        width:100%;
    }
    .about-us-uprow{
        justify-content: center;
        width:100%;
        box-sizing: border-box;
    }
    .about-us-uprow-column{
        width:100%;
        box-sizing: border-box;
    }
    .about-us-uprow-column-textbox{
        width:100%;
        box-sizing: border-box;
    }
    .about-us-row{
        display:flex;
    }
}
.product-page{
    width:100%;
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
}
.product-container{
    width:100%;
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top:5%;
}
.image-container{
    object-fit: cover;
    flex-grow:1;
    margin:2px;
    object-position: top;
}
.product-images-container{
    display:flex;
    width:100%;
    padding:0px 25%;
    height:100vh;
}
.product-info-container{
    display:flex;
    box-sizing: border-box;
    width:30%;
    padding:40px;
    flex-direction: column;
    align-items: center;
}
.product-title{
    font-weight: 900;
    padding-top:32px;
    padding-bottom:32px;
    color:white;
    text-align: center;
    font-family: drover;
    font-size: 20px;
}
.product-add-to-cart{
    background-color: rgb(34,34,34);
    padding:12px;
    text-align: center;
    font-weight: 900;
    color:white;
    margin-top:32px;
}
.product-add-to-cart-deactivated{
    background-color: rgba(34,34,34,.6);
}
.product-info-text{
    width:100%;
}
.product-sizes-container{
    width:100%;
    display:flex;
    gap:12px;
    flex-wrap: wrap;
    justify-content: center;
    margin:32px 12px;
}
.product-sizes-title{
    width:100%;
    font-weight: 900;
    text-align: center;
}
.size-box{
    border:1px solid rgb(34,34,34);
    padding:12px;
}
.active-size{
    background-color: rgb(34,34,34);
    color:white
}
.product-buttons-row{
    width:100%;
    display:flex;
    gap:12px;
}
.product-page-link{
    text-decoration: underline;
}
.product-description{
    color:white;
    text-align: start;
    padding:10px 24px;
    width:80%;
    flex-grow:1;
    font-size: 32px;
}
.artist-info-container{
    width:100%;
    box-sizing: border-box;
    padding:5%;
    display:flex;
    text-decoration: none;
    color:white;
}
.artist-social-media{
    width:20%;
    flex-grow:1;
    height:100%;
    border-left:2px solid white;
}
.artist-social-container{
    padding:12px 24px;
    display:flex;
    align-items: center;
    gap:24px;
    cursor: pointer;
    text-decoration: none;
}
.artist-social-name{
    color:white;
    transition: .5s all
}
.artist-social-container:hover .artist-social-name{
    padding-left:18px;
}
.social-icon{
    border:1px solid white;
    padding:8px;
    max-width:32px;
    flex-grow:1
}
.artist-discography-container{
    width:100%;
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
}
.artist-discography-container{
    width:100%;
    display: flex;
    justify-content: center;
    max-width: 100%;
}
.artist-discography-element{
    width:100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
@media only screen and (max-width:768px){
    .artist-info-container{
        width:100%;
        flex-wrap: wrap;
    }
    .product-description{
        width:100%;
    }
    .artist-social-media{
        width:100%;
        height:min-content
    }
    .artist-discography-container{
        width:100%;
        height:min-content
    }
    .image-container{
        width:100%;
    }
}
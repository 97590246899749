.hero-container{
    display:flex;
    width:100%;
    height:75vh;
    position: relative;
}
.hero-images-container{
    display:flex;
    flex-direction: column;
    width:100%;
    height:100%;
    position: relative;
}
.gioko-hero{
    position: absolute;
    width: 50%;
    height: 65%;
    object-fit: cover;
    object-position: 50% 20%;
    bottom: 0;
}
.alexandrescu-hero{
    position: absolute;
    width: 30%;
    height: 65%;
    object-fit: cover;
    object-position: 50% 20%;
    right: 0;
}
.vladutz-hero{
    position: absolute;
    width: 30%;
    height: 35%;
    object-fit: cover;
    object-position: 50% 10%;
    right: 0;
    bottom:0;
}
.kaisser-hero{
    object-position: 50% 20%;
    position: absolute;
    height:50%;
    bottom:0;
    width:100%;
    object-fit:cover;
}
.siralex-hero{
    height:70%;
    width:100%;
    object-fit: cover;
    object-position: 50% 25%;
    padding-right:30%;
    box-sizing: border-box;
}
.hero-cover{
    width:100%;
    height: 100%;
    background-color: rgba(0,0,0,.6);
    position: absolute;
}
.hero-skull-container{
    position: absolute;
    width:100%;
    height: 100%;
    display:flex;
    justify-content: center;
    align-items: start;
    padding-top: 10%;
}
.skull-image{
    height:50%;
    object-fit: contain;
}
.left-speaker-container,.right-speaker-container{
    display: flex;
    position: absolute;
    width:100%;
    height: 100%;
}
.speaker-image{
    width: 20%;
    object-fit: contain;
}
.right-speaker-container{
    transform: scaleX(-1);
}
.hero-logo-container{
    position: absolute;
    width:100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: end;
}
.hero-logo{
    width:50%;
    object-fit: contain;
    margin-bottom:5%;
    transition: .5s all;
    z-index: 1;
    cursor: pointer;
}
.hero-logo:hover{
    scale:1.15
}
.left-hero-text-container,.right-hero-text-container{
    width: 100%;
    height:100%;
    display:flex;
    position: absolute;
    color: white;
    align-items: center;
    overflow: hidden;
}

.left-hero-text-container{
    justify-content: start;
}
.right-hero-text-container{
    justify-content: end;
}
.hero-text{
    writing-mode: vertical-rl;
    text-orientation: upright;
    font-family: bc;
    font-size: 32px;
    padding: 0px 64px;
    transition: .5s all;
    z-index: 1;
    cursor:default
}
.hero-text:hover{
    scale:1.15
}

@media only screen and (max-width:900px){
    .hero-container{
        height:50vh
    }
    .hero-logo{
        display:none
    }
    .skull-image{
        width: 80%;
        height:100%;
    }
}
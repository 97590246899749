.footer-container{
    border-top:1px solid white;
    display: flex;
    padding:32px;
    margin:32px;
    flex-wrap: wrap;
}
.footer-subcontainer{
    width:50%;
    flex-grow:1;
    height:100%;
    display: flex;
    flex-wrap: wrap;
    align-content: start;
    justify-content: center;
}
.footer-copyright{
    width:100%;
    color:white;
    text-align: center;
    border-top:1px solid white;
    padding:24px;
    margin:12px;
}
.container-links{
    color:white;
    width:100%;
    display: flex;
    justify-content: center;
    text-decoration: none;
    padding:8px;
    font-size: 2vw;
}
.container-links:hover{
    text-decoration: underline;
}
.container-title{
    font-size: 1vw;
    font-family: drover;
    width: 100%;
    font-size: 32px;
    color: white;
    text-align: center;
    margin-bottom:32px
}
.right-subcontainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.container-logo{
    width:60%;
    height: 100%;
    object-fit: contain;
}

@media only screen and (max-width:900px){
    .footer-subcontainer{
        width:100%;
        height:min-content;
        padding:32px 0px;
    }
    .container-links{
        font-size: 32px;
    }
}
.homepage-container {
    display: flex;
    flex-direction: column;
    background-color: #000;
}

.infotext {
    display: flex;
    flex-direction: column;
}
.about-us-home-title{
    font-family: drover;
    font-size: 64px;
    color:white;
    width:100%;
    text-align: center;
    margin-bottom:32px;
}

.infotext {
    display: flex;
    flex-direction: column;
}
.about-us-container{
    width:100%;
    display:flex;
    padding:2% 5%
}
.about-us-section {
    display: flex;
    justify-content: center;
    min-height: 80vh; 
    width:100%;
}
.about-us-content{
    width:50%;
    flex-grow: 2;
}

.about-us-home-title {
    font-family: drover;
    font-size: 64px;
    color: white;
    margin: 0;
}
.about-us-image{
    width:25%;
    object-fit: cover;
    height: 100%;
}

.about-us-text {
    font-size: 48px;
    padding:24px;
    color: white;
    font-family: drover;
}

.about-us-image {
    flex: 1; 
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-us-image img {
    width: 100%;
    max-width: 300px; 
    border-radius: 8px; 
    object-fit: cover;
}

.carousel-section {
    margin: 50px 0;
    text-align: center;
}

.carousel-title {
    font-family: drover;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
    color: white;
}


.carousel-image {
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.contact-us-section {
    margin: 50px 0;
    padding: 50px 20px;
    text-align: center;
    background-color: #1c1c1c; 
    color: white;
    border-radius: 10px; 
    min-height: 50vh; 
}

.contact-us-title-container {
    margin-bottom: 20px;
}

.contact-us-home-title {
    font-family: drover;
    font-size: 64px; 
    font-weight: bold;
    color: white;
    margin: 0;
}

.contact-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    text-align: left;
}

.form-group {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.form-group label {
    font-size: 16px;
    font-weight: bold;
    color: white;
    display: block;
    margin-bottom: 8px;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
    border:none;
}

.contact-form-button {
    font-family: drover;
    display: inline-block;
    padding: 12px 20px;
    margin:24px 10px;
    font-size: 16px;
    font-weight: bold;
    color: rgb(34,34,34);
    background-color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
}
.contact-form-button:hover{
    background-color: transparent;
    color: white;
}

.embla {
    box-sizing: border-box;
    width: 100%;
    padding:10px 48px;
    --slide-height: 19rem;
    --slide-spacing: 1rem;
    --slide-size: 25%;
    margin-bottom:20px;
  }
  .embla__viewport {
    overflow: hidden;
  }
  .embla__container {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y pinch-zoom;
    margin-left: calc(var(--slide-spacing) * -1);
  }
  .embla__slide {
    cursor:pointer;
    flex: 0 0 var(--slide-size);
    min-width: 0;
    aspect-ratio: 1/1;
    object-fit: cover;
    padding-left: var(--slide-spacing);
  }
  .embla__image{
    width:100%;
    height:100%;
    object-fit: cover;
  }
  #carousel-KAISSER{
    object-position: 0% 0%;
  }
  #carousel-Gioko{
    object-position: 0% 0%;
  }
  #carousel-Alexandrescu{
    object-position: 0% 0%;
  }
  #carousel-PoL{
    object-position: 0% 0%;
  }
  .socials-container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width:100%;
    gap:10px;
    margin-bottom:32px
  }
  .small-social-container{
    width: 33%;
    box-sizing: border-box;
    padding: 32px;
    background-color: rgb(34,34,34);
    border-radius: 12px;
  }
  .big-social-container{
    width:100%;
    box-sizing: border-box;
    padding:2% 15%;
    margin:0px 25%;
    background-color: rgb(34,34,34);
    border-radius: 12px;
  }
  .empty{
    flex-grow: 1;
  }
  .social-logo{
    width:100%;
    font-size: 48px;
    color:white;
  }
  .social-text{
    color: white;
    text-decoration: none;
  }

  .social-logo,.social-container{
    transition: all .5s;
  }

  .social-container:hover .social-logo{
    transform: translateY(-10%);
  }
  .social-container:hover{
    background-color: rgb(64,64,64);
  }

  @media only screen and (max-width:900px){
    .about-us-image{
        display:none
    }
    .about-us-text{
      font-size: 32px;
    }
    .socials-container{
      padding:0
    }
    .small-social-container{
      margin:0;
      box-sizing: border-box;
      width:37%;
    }
    .contact-us-home-title{
      font-size:30px;
    }
    .embla__container{
      width:300%
    }
}
.login-title{
    color:rgb(34,34,34);
    width:50%;
    border-bottom:1px solid rgb(34,34,34);
    padding:12px;
    margin-bottom:64px;
    text-align:center;
}
.login-page{
    width:100%;
    display:flex;
    flex-wrap:wrap;
    justify-content: center;
    padding-top:10%;
    padding-bottom:10%;
}
.login-container{
    border-radius: 24px;
    padding:24px;
    display:flex;
    flex-wrap:wrap;
    justify-content: center;
    padding:24px;
}

.input{
    border:0;
    padding:16px;
    border:1px solid rgb(34,34,34);
    width:50%;
    box-sizing: border-box;
    margin:12px;
}
.login-button{
    background-color:rgb(34,34,34);
    padding:16px;
    color:white;
    width:50%;
    box-sizing: border-box;
    text-align: center;
    font-weight:200;
    margin-top:32px;
    transition:all .5s;
}
.login-button:hover{
    background-color:rgb(59, 59, 59)
}
.login-register-button{
    width:100%;
    padding:12px;
    text-align: center;
}
@font-face {
    font-family: mmpt;
    src: url("./assets/fonts/MMTP-M.otf");
    font-weight: 500;
  }

  @font-face {
    font-family: mmpt;
    src: url("./assets/fonts/MMTP-B.otf");
    font-weight: 900;
  }

  @font-face {
    font-family: mmpt;
    src: url("./assets/fonts/MMTP-SR.otf");
    font-weight: 200;
  }

  @font-face{
    font-family:bc;
    src: url("./assets//fonts//BC.ttf")
  }

  @font-face{
    font-family:cf;
    src: url("./assets//fonts//CF.ttf")
  }

  @font-face{
    font-family: drover;
    src:url("./assets//fonts//drover.ttf")
  }
body{
    padding:0;
    margin:0;
    background-color: black;
}
*{
    font-family: "Roboto", sans-serif;
    font-weight:400;
}

.pointer{
  cursor:pointer
}

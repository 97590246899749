.roster-page{
    padding:48px 64px;
    /*background-image: url("../../assets//backgrounds/04.jpg");*/
    background-size: cover;
    position: relative;
}
.roster-background{
    width:100vw;
    height: 150vh;
    margin-top:-32px;
    position: fixed;
    object-fit: cover;
    background-size: cover;
    z-index: -2;
    top:0;
}
.roster-cover{
    width:100vw;
    height: 150vh;
    margin-top:-32px;
    position: fixed;
    background-size: cover;
    z-index: -1;
    top:0;
}
.roster-title{
    width:100%;
    font-size: 32px;
    text-align: center;
    color:white;
    padding:24px;
    box-sizing: border-box;
    font-family: drover;
    font-size:48px;
}
.roster-container{
    z-index: 1;
    display:flex;
    justify-content: center;
    padding:0px 24px;
    gap:48px;
    box-sizing: border-box;
    flex-wrap: wrap;
}
.artist-card{
    cursor:pointer;
    flex-grow:1;
    box-sizing: border-box;
    padding:12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    perspective: 1000px;
    height:100vh
}
.artist-image{
    width:100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    margin:12px;
    transition: all .5s;
}
.artist-card:hover .artist-image{
    margin:0;
}
.artist-name{
    text-align: center;
    padding:12px 12px 0px 12px;
    color:white;
    font-size: 32px;
    font-family: mmpt;
    font-weight: 900;
}
.artist-image{
    height:100vh;
    width:100%;
    object-fit: cover;
    object-position: center;
}
.artist-card:nth-child(odd){
    width:25%;
    flex-grow: 1;
}
.artist-card:nth-child(even){
    width:50%;
    flex-grow: 1;
}



.artist-card-inner {
    width: 100%;
    height: 100%;
    transition: transform 0.4s;
    transform-style: preserve-3d; /* Preserve 3D transformation */
    position: relative;
  }
.artist-card:hover .artist-card-inner {
    transform: rotateY(180deg); /* Rotate card on hover */
  }
  
  .flip-card-front,
  .flip-card-back {
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden; /* Hide the back side when not facing the user */
}
  .flip-card-back p {
    font-family: drover;
    font-size: 32px;
  }
  .flip-card-front {
    background-color: #f9f9f9;
  }
  
  .flip-card-front img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0% 0%;  
  }
  #roster-SirAlex,#roster-AlexanderFabyann{
    object-position: 15% 15%;
  }
  .flip-card-back {
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotateY(180deg); /* Position the back side */
  }

  @media only screen and (max-width:900px){
    .artist-card:nth-child(odd),.artist-card:nth-child(even){
        width:100%;
        margin:0;
        padding:0;
    }
    .roster-container{
        gap:12px;
    }
    .roster-page{
        padding:12px;
    }
    .flip-card-back p{
        text-align: center;
    }
  }
.artist-page{
    display:flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    width:100%;
    justify-content: center;
    padding:10% 15%;
}
.input-image-button{
    background-color: white;
    padding:18px;
    border:1px solid rgb(34,34,34);
}
.create-product-input{
    padding:18px;
    border:solid 1px rgb(34,34,34);
    margin:12px;
    width:100%;
}
.create-description{
    height:120px;
    resize: none;
}
.create-product-row{
    display:flex;
    flex-wrap:wrap;
    justify-content: start;
    align-items: center;
    padding:12px;
    width:100%;
}
.stock-size-container{
    margin:10px;
    display:flex;
    flex-wrap:wrap;
    justify-content: start;
}
.create-small{
    width:min-content;
}
.stock-size-subcontainer{
    display:flex;
    flex-direction: row;
    flex-grow:1;
}
.stock-size-subcontainer > input {
    padding: 17px;
    border:1px solid rgb(34,34,34);
    width:min-content;
}
.stock-size-subcontainer > span {
    padding-top:18px;
    padding-bottom:18px;
    padding-right:6px;
    padding-left:6px;
}
.create-gender-select,.create-category-select{
    background-color:white ;
    border: 1px solid rgb(34,34,34);
    margin-left:16px;
    padding:18px;
}
.create-product-submit-container{
    display:flex;
    padding-right:16px;
    justify-content: end;
}
.create-product-submit-button{
    padding:16px;
    background-color: white;
    font-weight: 900;
    padding-left:24px;
    padding-right:24px;    
    color:black;
    border: 1px solid white;
    transition:.5s all;
    margin-top:64px;
}
.create-product-submit-button:hover{
    border: 1px solid white;
    background-color: transparent;
    color:white
}
.create-product-empty{
    flex-grow:2;
}

@media only screen and (max-width:768px){
    .create-small{
        width:100%;
        margin:12px 0;
    }
    .create-category-select,.create-gender-select{
        flex-grow: 1;
        margin:0px;
    }
    .create-product-row{
        width:100%;
        justify-content: center;
    }
    .create-category-select{
        margin-left:6px;
    }
    .create-gender-select{
        margin-right: 6px;
    }
}